import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`The CoreMedia product catalog supports this latter use case with content items of type
Category and Product.`}</p>
      <p parentName="blockquote">{`CoreMedia Content Cloud supports both use cases:`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`The CoreMedia product catalog based on content items of type Category and Product`}</li>
        <li parentName="ul">{`The connection with an eCommerce system and support in Studio with content items
of type Product Teaser, Augmented Page, Augmented Category,
Augmented Product and e-Marketing Spot`}</li>
      </ul>
    </blockquote>
    <h1>{`Getting Started`}</h1>
    <p>{`The CoreMedia product catalog is based on content items of type Category and
Product. The catalog structure is defined by links, where products link to their category
and categories link to their sub categories.`}</p>
    <p>{`By default, the catalog and its content is shown in the tree view of the library as a separate
root folder and not below `}<em parentName="p">{`All Content`}</em>{`. Therefore, you can only search for products
and categories below the Catalog root folder. To find out more and learn how you can switch this behavior, please refer to `}<a parentName="p" {...{
        "href": "https://documentation.coremedia.com/cmcc-10/current/webhelp/studio-user-en/content/CoreMediaProductCatalogContentMode.html%5BCoreMedia"
      }}>{`https://documentation.coremedia.com/cmcc-10/current/webhelp/studio-user-en/content/CoreMediaProductCatalogContentMode.html[CoreMedia`}</a>{` Product Catalog Content Mode].`}</p>
    <h2>{`Steps`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Creating a new category`}</p>
        <p parentName="li">{`Select the parent category of the new category and either click `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "24px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/ae0b39a87886c9f3e8877dee789b0d3d/30a1f/CreateContentIcon.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/ae0b39a87886c9f3e8877dee789b0d3d/0a7fd/CreateContentIcon.webp 24w"],
                  "sizes": "(max-width: 24px) 100vw, 24px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/ae0b39a87886c9f3e8877dee789b0d3d/30a1f/CreateContentIcon.png 24w"],
                  "sizes": "(max-width: 24px) 100vw, 24px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/ae0b39a87886c9f3e8877dee789b0d3d/30a1f/CreateContentIcon.png",
                  "alt": "image,32,32",
                  "title": "image,32,32",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{` and select `}<em parentName="p">{`Category`}</em>{` or select `}<em parentName="p">{`Create Category`}</em>{` from the context menu.
The new Category content will be created and automatically linked to the parent category. The form of the new category opens up. Add at least a `}<em parentName="p">{`Category Title`}</em>{`. This title is, for example, used in the breadcrumb navigation.`}</p>
        <p parentName="li"><img parentName="p" {...{
            "src": "/be518d3b41015263294731dae84be1af/editing-product-catalog.png",
            "alt": null
          }}></img></p>
      </li>
    </ol>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Creating a New Product`}</li>
    </ol>
    <p>{`Select the category to which you want to add the product and click the icon or select `}<em parentName="p">{`Create Product`}</em>{` from the context menu. Enter a name and click `}{`[`}<em parentName="p">{`Create`}</em>{`]`}{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/787d8ab0eb6bf72ca3c3ead3b6028b71/new-product-product-catalog.png",
        "alt": null
      }}></img></p>
    <p>{`The new Product content will be created and automatically linked to the parent
category. The form of the new product opens up. Enter at least a `}<em parentName="p">{`Product Title`}</em>{` and a
unique product ID in `}<em parentName="p">{`Product Code`}</em>{`.`}</p>
    <h2>{`How to go on`}</h2>
    <p>{`Congratulations, you have now learned how to work with `}<em parentName="p">{`Categories`}</em>{` and `}<em parentName="p">{`Products`}</em>{` and how to use the CoreMedia Product Catalog.`}</p>
    <p>{`To get more information, please visit our `}<a parentName="p" {...{
        "href": "https://documentation.coremedia.com"
      }}>{`documentation section`}</a>{` and have a look at the Studio User Manual.`}</p>
    <p>{`Need support? Do not hesitate and reach out at `}<a parentName="p" {...{
        "href": "mailto:support@coremedia.com."
      }}>{`support@coremedia.com.`}</a>{` `}</p>
    <p>{`And if you notice any problems, or find bugs, our Support Team will be glad to help as well.`}</p>
    <p>{`You can also visit the CoreMedia `}<a parentName="p" {...{
        "href": "https://support.coremedia.com"
      }}>{`Help Center`}</a>{`. It contains a Knowledge Base along with the section "Technical Videos". Here you will find more technical related videos and short tutorials.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      